import React, {useState} from 'react';
import EnterpriseForm from '../../components/enterprise-form';
import SimpleLayout from '../../components/layout_simple';
import './styles.scss';
const EnterprisePage = () => {
  const [showSentMsg, setShowSentMsg] = useState(false);

  return (
    <SimpleLayout isClickable>
      <EnterpriseForm setShowSentMsg={setShowSentMsg} />
    </SimpleLayout>
  );
};

export default EnterprisePage;
